import {acceptHMRUpdate, defineStore} from "pinia";
import {createDateText, textDate} from "~/libs/date";
import {useClinics} from "~/store/clinics";
import {useDocs} from "~/store/docs";
import {useSpecials} from "~/store/specials";
import {usePrices} from "~/store/price";
import {useHome} from "~/store/home";
import {useUi} from "~/store/ui";
import {useApp} from "~/store/app/app";
import { useStat } from "~/store/stat";

export const useSlots = defineStore("slots", {
    state: () => {
        const config = useRuntimeConfig();

        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        const listDocsHome = {
            "486ec723-3fed-11ed-814a-e4a222cb1759": "Ахмадиева Эльвина",
            "486ec724-3fed-11ed-814a-e4a222cb1759": "Гирфанова Гузель",
            "486ec722-3fed-11ed-814a-e4a222cb1759": "Зиетова Диана",
            "24aa0b2c-2524-11ed-8147-cb424a7e2392": "Участок 1",
            "22875665-252a-11ed-8147-cb424a7e2392": "Участок 2",
            "22875666-252a-11ed-8147-cb424a7e2392": "Участок 3",
            "22875667-252a-11ed-8147-cb424a7e2392": "Участок 4",
        };

        return {
            apiBase: config.public.apiBase,
            requestOptions,

            typeVisit: "visit",
            type: null,

            order: false,
            sendOrder: false,

            open: false,
            complete: false,

            target: null,
            docId: null,
            specialId: null,
            priceId: null,

            mapApprove: null,

            calendar: [],

            date: null,
            time: null,

            patient: {
                address: null,
                apartment: null,
                caddress: null,
                front: null,
                floor: null,
                phone: null,
                birthday: {
                    day: null,
                    month: null,
                    year: null,
                },
                fio: null,
                reason: null,
                comment: null,
            },
            patientDebug: {
                phone: "+7 (921) 409 06 81",
                birthday: {
                    day: 1,
                    month: 1,
                    year: 2022,
                },
                fio: "Тестовый пациент",
                comment: "Тестовая запись",
            },

            policyAgree: false,

            bookId: null,
            cachBookLink: null,

            listDocsHome,
        };
    },

    getters: {
        dates(state) {
            const result = [];
            if (this.calendar) {
                for (let date in this.calendar) {
                    result.push({start: new Date(date), end: new Date(date)});
                }

                return result;
            } else {
                return null;
            }
        },
        timeList() {
            const result = [];

            if (this.date && this.calendar[textDate(this.date)]) {
                if (Object.values(this.calendar[textDate(this.date)]).length) {
                    for (let slot of this.calendar[textDate(this.date)]) {
                        result.push({...slot});
                    }
                }
            }

            function compare(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }

            result.sort(compare);

            return result;
        },
        calculateTime(state) {
            return async (id, target) => {
                if (target === "home") {
                    const homeStore = useHome();
                    return homeStore.zone.time;
                }
                let result;
                if (id) {
                    const docsStore = useDocs();
                    let doc = Number.isInteger(id)
                        ? docsStore.getDocById(id)
                        : docsStore.getDocByMisId(id);

                    switch (target) {
                        case "doc":
                            result = doc?.attributes?.time;
                            break;
                        case "special":
                            result = doc?.attributes?.time;
                            break;
                        case "price":
                            const pricesStore = usePrices();
                            const price = pricesStore.getPriceById(+this.priceId);
                            if (!price?.attributes?.time) {
                                result = doc?.attributes?.time ? doc?.attributes?.time : 0;
                            } else {

                                result = price.attributes.time;
                            }
                            break;
                    }
                }
                return result ? result : 0;
            };
        },
        getListDocsMisIdTarget: (state) => {
            return async (targetType = null, targetId = null) => {
                const listDocs = [];
                targetType = targetType ? targetType : state.target;

                switch (targetType) {
                    case "doc":
                        const docsStore = useDocs();
                        if (!docsStore.listDocs.length) {
                            await docsStore.fetchDocs();
                        }
                        targetId = targetId ? targetId : state.docId;
                        listDocs.push(docsStore.getDocById(targetId).attributes.docId);
                        break;
                    case "price":
                        const pricesStore = usePrices();
                        targetId = targetId ? +targetId : +state.priceId;

                        // let price = pricesStore.getPriceById(targetId);
                        // if (!price) {
                        const price = await pricesStore.fetchPrice(targetId);
                        // }
                        if (price?.attributes?.docs?.data) {
                            for (let doc of price.attributes.docs.data) {
                                listDocs.push(doc.attributes.docId);
                            }
                        }
                        break;
                    case "special":
                        const specialsStore = useSpecials();
                        targetId = targetId ? targetId : state.specialId;

                        // let special = specialsStore.getSpecialById(targetId);
                        // if (!special) {
                        const special = await specialsStore.fetchSpecial(targetId);
                        // }
                        if (special?.attributes?.docs?.data) {
                            for (let doc of special.attributes.docs.data) {
                                listDocs.push(doc.attributes.docId);
                            }
                        }
                        break;
                }

                return listDocs;
            };
        },
        getNearDateText(state) {
            return (slots) => {
                return createDateText(Object.keys(slots).sort()[0]);
            };
        },
        getNearDate(state) {
            return (slots) => {
                return Object.keys(slots).sort()[0];
                // return "1 января";
            };
        },
        targetTitle(state) {
            let result = "";
            switch (state.target) {
                case "price":
                    const pricesStore = usePrices();
                    const price = pricesStore.getPriceById(state.priceId);
                    if (price)
                        result = ` || Запись на услугу: ${price.attributes.title} || `;
                    break;
                case "special":
                    const specialsStore = useSpecials();
                    const special = specialsStore.getSpecialById(state.specialId);
                    if (special)
                        result = ` || Запись по специализации: ${special.attributes.title} || `;
                    break;
                case "doc":
                    const docsSlots = useDocs();
                    const doc = docsSlots.getDocById(state.docId);
                    if (doc)
                        result = ` || Запись на врача: ${doc.attributes.lname} ${doc.attributes.fname} || `;
                    break;
                case "home":
                    result = ` || Выезд на дом || `;
                    break;
            }
            if (state.typeVisit === "online") result += " || Онлайн-консультация || ";
            return result;
        },
    },

    actions: {
        async openHome() {
            const homeStore = useHome();
            const slotsStore = useSlots();
            const statStore = useStat();

            statStore.goal('home_start');

            homeStore.zone = null;
            homeStore.address = null;
            homeStore.coords = [];
            slotsStore.target = null;
            slotsStore.date = null;
            slotsStore.time = null;
            slotsStore.mapApprove = null;

            this.complete = false;
            this.typeVisit = "home";
            this.type = "home";
            this.open = true;
        },
        async homeCompile() {
            this.mapApprove = true;
            const homeStore = useHome();

            if (homeStore?.zone?.docs?.data) {
                const listDocsMisId = homeStore.zone.docs.data.map(
                    (doc) => doc.attributes.docId
                );

                this.calendar = await this.getSlots(listDocsMisId, "home");

                this.target = "home";
            }
        },
        openOrder(type = null, target = null) {
            this.complete = false;
            this.order = true;
            if (type) {
                this.setTarget(type, target);
            }
        },
        openOrderProgramCustom(type = null) {
            this.complete = false;
            this.order = true;
            this.type = type;
        },
        openDialog(type, target = null, typeVisit = "visit") {
            if (target !== 'home') {
                const appStore = useApp();
                appStore.type = type;
                appStore.unsetStart();
                switch (type) {
                    case 'price':
                        appStore.start.priceId = target;
                        appStore.priceId = target;
                        break;
                    case 'special':
                        appStore.start.specialId = target;
                        appStore.specialId = target;
                        break;
                    case 'doc':
                        appStore.start.docId = target;
                        appStore.docId = target;
                        break;
                }

                appStore.open = true;
            } else {
                this.complete = false;
                this.type = type;
                this.typeVisit = typeVisit;
                this.setTarget(type, target);
                this.open = true;
            }
        },
        setTarget(type, target) {
            this.unsetTarget();
            if (target) {
                this.target = type;
                switch (type) {
                    case "doc":
                        this.docId = target;
                        break;
                    case "special":
                        this.specialId = target;
                        break;
                    case "price":
                        this.priceId = target;
                        break;
                }
            }
        },
        unsetTarget() {
            this.target = null;
            this.docId = null;
            this.priceId = null;
            this.specialId = null;
            this.calendar = [];
        },
        async updateCalendar() {
            this.clearVisit();
            const listDocsMisId = await this.getListDocsMisIdTarget();
            const slots = await this.getSlots(listDocsMisId, this.target);
            this.calendar = slots;
        },
        async getSlots(listDocsMisId, target = "doc") {
            const slots = [];
            const clinicStore = useClinics();
            const clinicId = clinicStore.clinicId;

            if (listDocsMisId && !Array.isArray(listDocsMisId)) {
                listDocsMisId = [listDocsMisId];
            }

            for (let docId of listDocsMisId) {
                if (docId !== null) {
                    const duration = await this.calculateTime(docId, target);
                    const categoryIds = await this.calculateCategoryIds(docId, target);
                    // const duration = 0;

                    await fetch(
                        `${this.apiBase}/api/mis/slots?clinicId=${clinicId}&doctorId=${docId}&duration=${duration}`,
                        this.requestOptions
                    )
                        .then((response) => {
                            if (response.status !== 200) return null;
                            return response.json();
                        })
                        .then((slotsResponse) => {
                            if (slotsResponse && Array.isArray(slotsResponse)) {
                                for (let slot of slotsResponse) {
                                    const {
                                        from: {_text: time},
                                        priemID: {_text: slotCategoryId},
                                    } = slot;
                                    if (!categoryIds.includes(+slotCategoryId)) {
                                        continue;
                                    }
                                    const name = this.getTime(time);
                                    const rDate = this.getDate(time);
                                    const slotInDate = slots[rDate]?.find(
                                        (item) => item.value === time
                                    );

                                    if (slotInDate) {
                                        if (!slotInDate.listDocId.includes(docId)) {
                                            slotInDate.listDocId.push(docId);
                                        }
                                    } else {
                                        slots[rDate] = [
                                            ...(slots[rDate] ?? []),
                                            {name, value: time, listDocId: [docId]},
                                        ];
                                    }
                                }
                            }
                        })
                        .catch((error) => console.log("error", error));
                }
            }

            return slots;
        },
        async calculateCategoryIds(docId, target) {
            const docsStore = useDocs();
            let result = [];
            switch (target) {
                case "home":
                    if (!docsStore.listDocs.length) {
                        await docsStore.fetchDocs();
                    }
                    const doc = await docsStore.getDocByMisId(docId);
                    if (doc?.attributes?.outsiteSlots) {
                        result = [4];
                    } else {
                        result = [1, 2, 3];
                    }
                    break;
                default:
                    result = [1, 2, 3];
            }
            return result;
        },
        clearVisit() {
            this.time = null;
            this.date = null;
        },
        async book() {
            const clinicStore = useClinics();
            const clinicId = clinicStore.clinicId;
            const docsStore = useDocs();
            const uiStore = useUi();
            const result = {};


            let doc;
            let docId;
            let cach = '';
            if (this.target === "doc") {
                doc = docsStore.getDocById(this.docId);
                docId = doc.attributes.docId;
                cach = doc.attributes.cach;
            } else {
                const day = this.calendar[textDate(this.date)];
                const time = day.find((item) => item.value === this.time);
                docId = time.listDocId[0];
                doc = docsStore.getDocByMisId(docId);
                this.docId = doc.id;
            }
            result.doctor = `${doc.attributes.fname} ${doc.attributes.lname}`;
            result.clinic = clinicStore.getClinicById(clinicId).attributes.title;

            const currentDate = new Date();
            const time = this.time;
            let endTime = new Date(time);
            if (currentDate > endTime) {
                return false;
            }
            result.time = time;
            result.time =
                result.time.split(" ")[0].split("-").reverse().join(".") +
                " " +
                result.time.split(" ")[1];

            result.clinicId = clinicStore.clinicId;
            result.target = this.targetTitle;

            const name = this.patient.fio;
            const phone = this.patient.phone;
            const birthDay = `${this.patient.birthday.year}-${
                this.patient.birthday.month < 10
                    ? "0" + this.patient.birthday.month
                    : this.patient.birthday.month
            }-${
                this.patient.birthday.day < 10
                    ? "0" + this.patient.birthday.day
                    : this.patient.birthday.day
            }`;
            const reason = this.patient.reason === 'По болезни' ? 'Прием по болезни. ' : '';
            const comment = reason + this.patient.comment + " [" + result.target + "]";
            const duration = await this.calculateTime(docId, this.target);
            const now = new Date();


            const requestOptions = {
                method: "GET",
                redirect: "follow",
            };


            this.bookId = await fetch(
                `${this.apiBase}/api/mis/book?clinicId=${clinicId}&doctorId=${docId}&name=${name}&phone=${phone}&birthDay=${birthDay}T00:00:00.000Z&time=${time}&comment=${comment}&duration=${duration}&now=${now}&cach=${cach}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));

            if (cach) {
                let cachLink = await fetch(
                    `${this.apiBase}/api/mis/cach?clinicId=${clinicId}&bookId=${this.bookId}`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => result)
                    .catch((error) => console.log("error", error));

                this.cachBookLink = cachLink["_text"].replace('&amp;', '&');
            }

            // result.bookId = result.book.bookId._text;
            result.duration = duration ? duration : "Стандартный слот врача";
            result.bookId = this.bookId;

            if (this.bookId) {
                return result;
            }

            return false;
        },

        async bookHome() {
            const homeStore = useHome();
            const clinicStore = useClinics();
            const clinicId = clinicStore.clinicId;
            const docsStore = useDocs();
            const uiStore = useUi();
            if (!docsStore.listDocs.length) {
                await docsStore.fetchDocs();
            }
            const result = {};


            const day = this.calendar[textDate(this.date)];
            const timeTmp = day.find((item) => item.value === this.time);
            let docId = timeTmp.listDocId[0];
            let doc = docsStore.getDocByMisId(docId);

            result.doctor = `${doc.attributes.fname} ${doc.attributes.lname}`;
            result.clinic = clinicStore.getClinicById(clinicId).attributes.title;

            const currentDate = new Date();
            const time = this.time;
            let endTime = new Date(time);
            if (currentDate > endTime) {
                return false;
            }
            result.time = time;
            result.time =
                result.time.split(" ")[0].split("-").reverse().join(".") +
                " " +
                result.time.split(" ")[1];
            endTime = new Date(endTime.getTime() + 120 * 60000);
            result.end = `${endTime.getDate()}.${endTime.getMonth() + 1}.${endTime.getFullYear()} ${endTime.getHours()}:${String(endTime.getMinutes()).padStart(2, '0')}:${String(endTime.getSeconds()).padStart(2, '0')}`;
            result.cost = homeStore.zone.cost;

            result.clinicId = clinicStore.clinicId;
            result.target = this.targetTitle;

            const name = this.patient.fio;
            const phone = this.patient.phone;
            const birthDay = `${this.patient.birthday.year}-${
                this.patient.birthday.month < 10
                    ? "0" + this.patient.birthday.month
                    : this.patient.birthday.month
            }-${
                this.patient.birthday.day < 10
                    ? "0" + this.patient.birthday.day
                    : this.patient.birthday.day
            }`;
            const comment =
                this.patient.comment +
                " [" +
                result.target +
                "]" +
                " Адрес: " +
                this.patient.address +
                (this.patient.front ? "пар. " + this.patient.front + ", " : "") +
                (this.patient.frame ? "корп. " + this.patient.frame + ", " : "") +
                (this.patient.apartment ? "кв. " + this.patient.apartment : "") +
                (this.patient.caddress
                    ? " | Комментарий: " + this.patient.caddress + ""
                    : "");
            const duration = await this.calculateTime(docId, this.target);
            const now = new Date();
            const cach = homeStore.zone.cach;

            const requestOptions = {
                method: "GET",
                redirect: "follow",
            };

            this.bookId = await fetch(
                `${this.apiBase}/api/mis/book?clinicId=${clinicId}&doctorId=${docId}&name=${name}&phone=${phone}&birthDay=${birthDay}T00:00:00.000Z&time=${time}&comment=${comment}&duration=${duration}&now=${now}&cach=${cach}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));

            if (cach) {
                let cachLink = await fetch(
                    `${this.apiBase}/api/mis/cach?clinicId=${clinicId}&bookId=${this.bookId}&home=1`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => result)
                    .catch((error) => console.log("error", error));

                this.cachBookLink = cachLink["_text"].replace('&amp;', '&');
            }

            // result.bookId = result.book.bookId._text;
            result.duration = duration;
            result.bookId = this.bookId;

            if (this.bookId) {
                return result;
            }

            return false;
        },

        async cancelBook() {
            const clinicStore = useClinics();
            const clinicId = clinicStore.clinicId;

            const requestOptions = {
                method: "GET",
                redirect: "follow",
            };

            await fetch(
                `${this.apiBase}/api/mis/cancelBook?clinicId=${clinicId}&bookId=${this.bookId}`,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => result)
                .catch((error) => console.log("error", error));

            // this.target = null;
        },
        getDate(str) {
            let arr = str.split(" ");
            return `${arr[0]}`;
        },
        getTime(str) {
            let arr = str.split(" ");
            let arrTime = arr[1].split(":");
            return `${arrTime[0]}:${arrTime[1]}`;
        },
        createDateText(date) {
            let dateResult = new Date(date);
            let months = [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ];

            return dateResult.getDate() + " " + months[dateResult.getMonth()];
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSlots, import.meta.hot));
}
