import { acceptHMRUpdate, defineStore } from "pinia";
import { useClinics } from "~/store/clinics";
import { usePatient } from "~/store/app/patient";

export const useStat = defineStore("stat", {
    state: () => {
        const counterYm = 91740071;
        const callTouchConf = {
            ct_site_id: "57936",
            mod_id: "85ai74lm"
        };

        return {
            callTouchConf,
            counterYm
        };
    },
    getters: {},
    actions: {
        goal(goal) {
            const clinicsStore = useClinics();
            const region = clinicsStore.clinicRegion;

            if (typeof ym === "undefined") return;
            ym(91740071, "reachGoal", `app_v2_${goal}`);
            ym(91740071, "reachGoal", region);
        },
        yandex(payload) {
            if (ym) {
                const type = "reachGoal";
                const action = payload.action + "_v1";
                ym(this.counterYm, type, action);
            }
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //     'event': 'formGTM'
            // });
        },
        callTouch(payload) {
            const clinicsStore = useClinics();
            const region = clinicsStore.clinicRegion;
            if (!payload.fio || !payload.phone) {
                const patientStore = usePatient();
                payload.fio = patientStore.patient.name;
                payload.phone = patientStore.patient.phone;
                payload.email = patientStore.patient.email;
            }

            const ct_data = {
                fio: payload.fio,
                phoneNumber: payload.phone,
                email: payload.email || "",
                subject: payload.subject + ` ${region}` || "Заявка" + ` ${region}`,
                tags: `kids,${region},${payload.tag}`,
                comment: "",
                requestUrl: payload.requestUrl || "https://kids.fomin-clinic.ru/",
            };

            if (window?.ct) {
                ct_data.sessionId = window.ct("calltracking_params", this.callTouchConf.mod_id)?.sessionId;

                if (ct_data.sessionId) {
                    let _createPropsString = (obj) => {
                        let props = Object.assign(obj);
                        return Object.keys(props)
                            .reduce(function(a, k) {
                                a.push(k + "=" + encodeURIComponent(props[k]));
                                return a;
                            }, [])
                            .join("&");
                    };

                    const statStatus = fetch("https://api.calltouch.ru/calls-service/RestAPI/requests/" + this.callTouchConf.ct_site_id + "/register/", {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: _createPropsString(ct_data)
                    }).then((res) => res.json());
                }
            }
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStat, import.meta.hot));
}
